import styled from "styled-components";
import ICONS from "../../assets";
import { device } from "../../Constants/Devices";
import profilePato from "../../assets/ProfilePato.jpg";

const AboutUs = () => {
  return (
    <Container>
      <TitleContainer>
        <Title>About Us</Title>
        <Description>
          Welcome to JavaJobs, your go-to platform for finding the best
          Java-related jobs. We connect talented Java developers with top-tier
          companies, making your job search simple and hassle-free. Simplify
          your job search with JavaJobs.
        </Description>
      </TitleContainer>
      <SecondColumnContainer>
        <SecondColumn>
          <TeamContainer>
            <MemberContainer>
              <ImageContainer>
                <Image src={profilePato} />
              </ImageContainer>
              <MemberInfo>
                <TeamTitleContainer>
                  <TeamSubTitle>Patricio Toledo</TeamSubTitle>
                  <SubTitle>Founder</SubTitle>
                </TeamTitleContainer>
                <DescriptionContainer>
                  <MemberDescription>
                    As a seasoned Full Stack Engineer with years of experience
                    in software development for clients in the US, I have a
                    diverse skill set and an unwavering passion for technology.
                    I have worked with various programming languages and
                    technologies throughout my career, including Java, which I
                    find particularly interesting and challenging. Having
                    searched for Java jobs in the past, I noticed the lack of a
                    comprehensive platform for job listings, which led me to
                    create my own. My platform aims to connect Java developers
                    with exciting job opportunities and provide a seamless
                    experience for both job seekers and employers.
                  </MemberDescription>
                </DescriptionContainer>
                <SocialMediaContainer>
                  <SocialMedia
                    href="https://www.linkedin.com/in/patricio-toledo-1a8000166/"
                    target="_blank"
                  >
                    <ICONS.Linkedin />
                  </SocialMedia>
                </SocialMediaContainer>
              </MemberInfo>
            </MemberContainer>
          </TeamContainer>
        </SecondColumn>
      </SecondColumnContainer>
    </Container>
  );
};

const SocialMedia = styled.a`
  width: 1.5rem;
`;

const SocialMediaContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  --space-y-reverse: 0;
  margin-bottom: calc(1rem * var(--space-y-reverse));
  margin-top: calc(1rem * (1 - var(--space-y-reverse)));
`;

const MemberDescription = styled.p`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  list-style: none;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --space-y-reverse: 0;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  margin: 0;
  --text-opacity: 1;
  color: rgba(107, 114, 128, var(--text-opacity));
`;

const DescriptionContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  list-style: none;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  font-size: 1.125rem;
  line-height: 1.75rem;
  --space-y-reverse: 0;
  margin-bottom: calc(1rem * var(--space-y-reverse));
  margin-top: calc(1rem * (1 - var(--space-y-reverse)));
`;

const TeamSubTitle = styled.h4`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  list-style: none;
  --space-y-reverse: 0;
  line-height: 1.5rem;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  margin: 0;
  font-size: inherit;
  font-weight: inherit;
`;

const SubTitle = styled.p`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  list-style: none;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  margin: 0;
  --text-opacity: 1;
  color: rgba(75, 85, 99, var(--text-opacity));
  --space-y-reverse: 0;
  margin-bottom: calc(0.25rem * var(--space-y-reverse));
  margin-top: calc(0.25rem * (1 - var(--space-y-reverse)));
`;

const MemberInfo = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  list-style: none;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  --space-y-reverse: 0;
  margin-bottom: calc(1rem * var(--space-y-reverse));
  margin-top: calc(1rem * (1 - var(--space-y-reverse)));
`;

const TeamTitleContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  list-style: none;
  --space-y-reverse: 0;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.5rem;
`;

const ImageContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  --space-y-reverse: 0;
  list-style: none;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  padding-bottom: 66.666667%;
  position: relative;
`;

const Image = styled.img`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  --space-y-reverse: 0;
  list-style: none;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  border-style: solid;
  display: block;
  vertical-align: middle;
  max-width: 100%;
  border-radius: 0.5rem;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  width: 100%;
`;

const Container = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
`;

const TitleContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
`;

const SecondColumnContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  --space-y-reverse: 0;
  margin-bottom: calc(3rem * var(--space-y-reverse));
  margin-top: calc(3rem * (1 - var(--space-y-reverse)));

  @media screen and ${device.laptop} {
  }
`;

const SecondColumn = styled.ul`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  --space-y-reverse: 0;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  margin-top: -2rem;
`;

const TeamContainer = styled.li`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  --space-y-reverse: 0;
  list-style: none;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
`;

const MemberContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  --space-y-reverse: 0;
  list-style: none;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;

  @media screen and ${device.tablet} {
    display: grid;
    gap: 1.5rem;
    grid-gap: 1.5rem;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

const Title = styled.h1`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  margin: 0;
  font-weight: 800;
  line-height: 2.25rem;
  letter-spacing: -0.025em;
  font-size: 2.25rem;
`;

const Description = styled.p`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  margin: 0;
  font-size: 1.25rem;
  line-height: 1.75rem;
  --text-opacity: 1;
  color: rgba(107, 114, 128, var(--text-opacity));
  --space-y-reverse: 0;
  margin-bottom: calc(1rem * var(--space-y-reverse));
  margin-top: calc(1rem * (1 - var(--space-y-reverse)));
`;

export default AboutUs;
