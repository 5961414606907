import { ReactNode } from "react";
import styled from "styled-components";
import ICONS from "../../../assets";

export enum TagType {
  FEATURED_COMPANY = "Featuerd Company",
  REMOTE = "Remote",
  COMMUNITY_SPONSOR = "Community Sponsor",
}

const TagConfig = {
  [TagType.FEATURED_COMPANY]: {
    color: "#faca15",
    hoverColor: "#fce96a",
    icon: <ICONS.Star />,
  },
  [TagType.REMOTE]: {
    color: "#7edce2",
    hoverColor: "#afecef",
    icon: null,
  },
  [TagType.COMMUNITY_SPONSOR]: {
    color: "#f05252",
    hoverColor: "#f98080",
    icon: <ICONS.Heart />,
  },
};

interface Props {
  type: TagType;
}

const Tag = ({ type }: Props) => {
  const renderIcon = () => {
    if (TagConfig[type].icon) {
      return <IconContainer>{TagConfig[type].icon}</IconContainer>;
    }
    return null;
  };

  return (
    <Container
      color={TagConfig[type].color}
      hoverColor={TagConfig[type].hoverColor}
    >
      {renderIcon()}
      <Title>{type}</Title>
    </Container>
  );
};

const Container = styled.a<{ color: string; hoverColor: string }>`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  list-style: none;
  --gradient-from-color: #161e2e;
  --gradient-color-stops: var(--gradient-from-color),
    var(--gradient-to-color, rgba(22, 30, 46, 0));
  --gradient-to-color: #05505c;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  text-decoration: inherit;
  --bg-opacity: 1;
  background-color: ${(props) => props.color};
  border-radius: 9999px;
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  font-weight: 600;
  line-height: 1.25rem;
  margin-right: 0.5rem;
  padding-bottom: 0.25rem;
  padding-top: 0.25rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  --text-opacity: 1;
  cursor: pointer;

  &:hover {
    background-color: ${(props) => props.hoverColor};
  }
`;

const Title = styled.span`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  list-style: none;
  --gradient-from-color: #161e2e;
  --gradient-color-stops: var(--gradient-from-color),
    var(--gradient-to-color, rgba(22, 30, 46, 0));
  --gradient-to-color: #05505c;
  --bg-opacity: 1;
  font-weight: 600;
  line-height: 1.25rem;
  --text-opacity: 1;
  color: rgba(99, 49, 18, var(--text-opacity));
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
`;

const IconContainer = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  list-style: none;
  --gradient-from-color: #161e2e;
  --gradient-color-stops: var(--gradient-from-color),
    var(--gradient-to-color, rgba(22, 30, 46, 0));
  --gradient-to-color: #05505c;
  --bg-opacity: 1;
  font-weight: 600;
  line-height: 1.25rem;
  --text-opacity: 1;
  color: rgba(99, 49, 18, var(--text-opacity));
  fill: none;
  stroke: currentcolor;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  display: block;
  vertical-align: middle;
  height: 1rem;
  margin-right: 0.25rem;
  width: 1rem;
`;

export default Tag;
