import styled from "styled-components";
import { device } from "../../../Constants/Devices";
import JobOffer from "../../../Model/JobOffer";
import {
  ContainedButton,
  GradientTitle,
} from "../../../Styles/CommonComponents";
import CountryFilter from "../../Common/CountryFilter";
import Card from "../Card";

interface Props {
  jobOffers: JobOffer[];
  fetchMore: () => void;
  filterByCountry: (country: string) => void;
  isLast?: boolean;
}

const JobCardList = ({
  jobOffers,
  fetchMore,
  isLast,
  filterByCountry,
}: Props) => {
  return (
    <Container>
      <GradientTitle>New Jobs</GradientTitle>
      <CountryFilter
        onSelect={filterByCountry}
        onClear={() => filterByCountry("")}
      />
      <CardList>
        {jobOffers.map((jobOffer, key) => (
          <Card key={key} jobOffer={jobOffer} />
        ))}
      </CardList>
      {!jobOffers.length && <p>No job offers found</p>}
      {!isLast && !!jobOffers.length && (
        <ButtonContainer>
          <ContainedButton onClick={fetchMore}>Load more</ContainedButton>
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 1em;
`;

const CardList = styled.ul`
  padding: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4em;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media screen and ${device.mobileL} {
    width: 15%;
  }
`;

export default JobCardList;
