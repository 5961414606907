import { useState } from "react";
import { ThemeProvider } from "styled-components";
import { theme } from "./Styles/theme";
import { BrowserRouter } from "react-router-dom";
import Router from "./Routes/Router";
import { ToastContainer } from "react-toastify";
import GlobalStyle from "./Styles/GlobalStyle";

function App() {
  const [currentTheme, setCurrentTheme] = useState(theme.light);

  return (
    <ThemeProvider theme={currentTheme}>
      <GlobalStyle />
      <ToastContainer />
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
