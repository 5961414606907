import { Link } from "react-router-dom";
import styled from "styled-components";

export const ContainedButton = styled.div<{
  isDisabled?: boolean;
  isLoading?: boolean;
}>`
  width: 100%;
  padding: 1em 1.2em;
  text-align: center;
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  border-radius: 10px;
  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.from} 0%,
    ${(props) => props.theme.colors.to} 51%,
    ${(props) => props.theme.colors.from} 100%
  );
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background-position: right center;
  }

  ${(props) =>
    props.isDisabled &&
    `
    opacity: 0.5;
    cursor: not-allowed;
  `}

  ${(props) =>
    props.isLoading &&
    `
    opacity: 0.5;
    cursor: not-allowed;
    ::after {
        content: '';
        display: block;
        width: 1.5em;
        height: 1.5em;
        margin: 0 auto;
        border-radius: 50%;
        border: 2px solid #fff;
        border-top-color: #000;
        animation: spin 1s linear infinite;
    }
  `}
`;

export const GradientTitle = styled.h1`
  margin: 0;
  font-size: 2.5em;
  font-weight: 700;
  text-align: center;
  padding-bottom: 1em;

  transition: 0.5s;
  background-size: 200% auto;
  color: transparent;
  -webkit-background-clip: text;

  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.from} 0%,
    ${(props) => props.theme.colors.to} 51%,
    ${(props) => props.theme.colors.from} 100%
  );

  &:hover {
    background-position: right center;
    font-weight: 500;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  margin: 0;
  cursor: pointer;
  color: ${(props) => props.theme.colors.to};

  &:hover {
    color: ${(props) => props.theme.colors.textHovered};
  }
`;

export const CommonLink = styled(Link)`
  display: flex;
  gap: 1em;
  align-items: center;
  text-decoration: none;
`;

export const ErrorMessage = styled.span`
  color: ${(props) => props.theme.colors.error};
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-top: 0.25rem;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Label = styled.label`
  padding: 0 0.5em 0.5em 0;
`;

export const OptionalLabel = styled.span`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  line-height: 1.25rem;
  --text-opacity: 1;
  color: rgba(107, 114, 128, var(--text-opacity));
`;

export const Placeholder = styled.span`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  --bg-opacity: 1;
  background-color: rgba(244, 245, 247, var(--bg-opacity));
  height: 2.5rem;
  overflow: hidden;
  width: 2.5rem;
`;
