import Company from "../Model/Company";
import StringUtils from "./StringUtils";

class CompanyUtils {
    public parse = (rawCompany: any): Company => {
        return {
            ...rawCompany,
            description: !!rawCompany.description ? StringUtils.parseToMarkDown(rawCompany.description) : "",
        } as Company;
    }
}

export default new CompanyUtils();