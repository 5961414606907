import { ReactComponent as Star } from "./icons/star.svg";
import { ReactComponent as Heart } from "./icons/heart.svg";
import { ReactComponent as RightArrow } from "./icons/right-arrow.svg";
import { ReactComponent as Calendar } from "./icons/calendar.svg";
import { ReactComponent as Location } from "./icons/location.svg";
import { ReactComponent as Linkedin } from "./icons/linkedin.svg";

const ICONS = {
  Star,
  Heart,
  RightArrow,
  Calendar,
  Location,
  Linkedin,
};

export default ICONS;
