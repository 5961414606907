import styled from "styled-components";
import ICONS from "../../../assets";
import { device } from "../../../Constants/Devices";
import useCountries from "../../../Hooks/useCountries";
import { Money } from "../../../types/Money";
import JobOfferUtils from "../../../Utils/JobOfferUtils";
import StringUtils from "../../../Utils/StringUtils";
import { TagType } from "../../Common/Tag/Tag";

interface Props {
  companyName: string;
  featured?: boolean;
  salaryFrom?: string;
  salaryTo?: string;
  city?: string;
  country: string;
  currency?: Money;
  remote?: boolean;
  photoURL?: string;
}

const CompanyInformation = ({ country, currency, ...props }: Props) => {
  const { flag } = useCountries(country);

  const renderSalaryRange = () => {
    if (!props.salaryFrom || !props.salaryTo || !currency) {
      return null;
    }

    const parsedSalaryFrom = StringUtils.parseMoney(props.salaryFrom, currency);
    const parsedSalaryTo = StringUtils.parseMoney(props.salaryTo, currency);

    return (
      <DividerContainer>
        {`${parsedSalaryFrom} - ${parsedSalaryTo}`}
      </DividerContainer>
    );
  };

  const renderLocation = () => {
    const displayedCity = JobOfferUtils.parseCity(props.city);

    return (
      <>
        <DividerContainer>
          <SvgContainer>
            <ICONS.Location width="1.25rem" height="1.25rem" />
            <Label>{displayedCity}</Label>
          </SvgContainer>
        </DividerContainer>
        {flag && (
          <DividerContainer>
            <label>{country}</label>
            <Label>{flag}</Label>
          </DividerContainer>
        )}
      </>
    );
  };

  return (
    <Container featured={props.featured}>
      <CompanyContainer>
        {!!props.photoURL && <Logo src={props.photoURL} />}
        <Title>{props.companyName}</Title>
        {props.featured && <ICONS.RightArrow width="1rem" height="1rem" />}
      </CompanyContainer>
      {renderSalaryRange()}
      {renderLocation()}
    </Container>
  );
};

const Container = styled.div<{ featured?: boolean }>`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};

  ${(props) =>
    props.featured &&
    `    cursor: pointer;
    `}

  @media screen and ${device.mobileL} {
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
`;

const CompanyContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.2em;
`;

const Logo = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const Title = styled.h2`
  font-size: 1em;
  padding-left: 0.5em;
  font-size: inherit;
`;

const DividerContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding-bottom: 0.2em;
  align-items: center;
  gap: 0.5em;

  @media screen and ${device.mobileL} {
    border: 0 solid #d2d6dc;
    border-left-width: 1px;
    padding-left: 1em;
  }
`;

const SvgContainer = styled.div`
  padding-right: 0.5em;
`;

const Label = styled.label`
  padding-left: 0.5em;
`;

export default CompanyInformation;
