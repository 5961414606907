import styled from "styled-components";
import { ErrorMessage } from "../../../../Styles/CommonComponents";

type Option = {
  name: string;
  value: string | boolean;
  default?: boolean;
};

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  registerProps: any;
  options: Option[];
  errorMessage?: string;
}

const CustomRadioInput = (props: Props) => {
  return (
    <InputContainer>
      <label>{props.label}</label>
      <OptionsContainer>
        {!!props.options.length &&
          props.options.map((option, key) => {
            return (
              <OptionContainer key={key}>
                <RadioInput
                  id={key}
                  type="radio"
                  defaultChecked={option.default}
                  value={option.value}
                  {...props.registerProps}
                />
                <Label>{option.name}</Label>
              </OptionContainer>
            );
          })}
        {!!props.errorMessage && (
          <ErrorMessage>{props.errorMessage}</ErrorMessage>
        )}
      </OptionsContainer>
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  padding: 0.5em;
`;

const Label = styled.label`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  margin-left: 0.75rem;
`;

const OptionsContainer = styled.div`
  padding-top: 0.5em;
`;

const OptionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  gap: 0.5em;
  align-items: center;
`;

const RadioInput = styled.input<{}>`
  cursor: pointer;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  overflow: visible;
  line-height: inherit;
  box-sizing: border-box;
  padding: 0;
  -webkit-print-color-adjust: exact;
  appearance: none;
  background-origin: border-box;
  border-radius: 100%;
  border-width: 1px;
  display: inline-block;
  flex-shrink: 0;
  user-select: none;
  vertical-align: middle;
  height: 1rem;
  --text-opacity: 1;
  color: rgba(224, 36, 36, var(--text-opacity));
  width: 1rem;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;

  &:checked {
    border-color: transparent;
    background-color: currentColor;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 16 16'%3E%3Ccircle cx='8' cy='8' r='3'/%3E%3C/svg%3E");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }
`;

export default CustomRadioInput;
