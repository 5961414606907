import JobOffer, { JobOfferStatus } from "../Model/JobOffer";
import JobOfferUtils from "../Utils/JobOfferUtils";
import FirebaseService, { db } from "./FirebaseService";
import {
  addDoc,
  collection,
  getDocs,
  orderBy,
  query,
  where,
  QueryDocumentSnapshot,
  startAfter,
  limit,
  DocumentData,
} from "firebase/firestore/lite";
import CollectionUtils from "../Utils/CollectionUtils";
import CONFIG from "../config/config";

class JobOfferService {
  public getJobOffers = async (
    country?: string,
    cursor: QueryDocumentSnapshot<DocumentData> | null = null,
    currentLimit: number = 5
  ) => {
    const constraints = [
      where("status", "==", JobOfferStatus.PUBLISHED),
      orderBy("publishedDate", "desc"),
      limit(currentLimit),
    ];

    if (cursor) {
      constraints.push(startAfter(cursor));
    }

    if (country) {
      constraints.push(where("country", "==", country));
    }

    const q = query(
      collection(db, CONFIG.FIRESTORE.COLLECTIONS.JOB_OFFERS),
      ...constraints
    );

    const snapshot = await getDocs(q);
    const newCursor = snapshot.docs[snapshot.docs.length - 1];

    const rawJobOffers = CollectionUtils.parse(snapshot);
    const jobOffers = rawJobOffers.map(JobOfferUtils.parse);

    return {
      jobOffers,
      newCursor,
    };
  };

  public getJobOffer = async (jobOfferId: string): Promise<JobOffer> => {
    const rawJobOffer = await FirebaseService.getFirestoreDocumentById(
      CONFIG.FIRESTORE.COLLECTIONS.JOB_OFFERS,
      jobOfferId
    );

    return JobOfferUtils.parse(rawJobOffer);
  };

  public createJobOffer = async (jobOffer: Partial<JobOffer>) => {
    const jobCollection = collection(
      db,
      CONFIG.FIRESTORE.COLLECTIONS.JOB_OFFERS
    );
    const jobOfferDocRef = await addDoc(jobCollection, {
      ...jobOffer,
      publishedDate: new Date().toISOString(),
    });

    return {
      ...jobOffer,
      id: jobOfferDocRef.id,
    };
  };
}

export default new JobOfferService();
