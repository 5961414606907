import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'

TimeAgo.addDefaultLocale(en)

const timeAgo = new TimeAgo('en-US');

function getTimeAgo(date: Date): string {
    return timeAgo.format(date) as string;
}

const DateUtils = {
    getTimeAgo,
}

export default DateUtils