import { useEffect, useState } from "react";
import CompanyCardList from "../../Components/CompanyCard/CompanyCardList";
import HomePlaceHolder from "../../Components/PlaceHolder/Home";
import Company from "../../Model/Company";
import CompanyService from "../../Services/CompanyService";

const SubscribedCompany = () => {
  const [companies, setCompanies] = useState<Company[]>([]);

  useEffect(() => {
    CompanyService.getFeaturedCompanies().then(setCompanies);
  }, []);

  if (!companies.length) {
    return <HomePlaceHolder />;
  }

  return <CompanyCardList companies={companies} />;
};

export default SubscribedCompany;
