import { Navigate, Route, Routes } from "react-router-dom";
import styled from "styled-components";
import Home from "./Home/Index";
import Header from "../Components/Common/Header";
import CustomRoutes from "./CustomRoutes";
import Job from "./Job";
import ContentContainer from "../Components/Common/ContentContainer";
import Post from "./Post";
import SubscribedCompany from "./SubscribedCompany";
import ViewCompany from "./Company";
import AboutUs from "./AboutUs";

const Router = () => {
  return (
    <Container>
      <Header />
      <ContentContainer>
        <Routes>
          <Route path={CustomRoutes.HOME} element={<Home />} />
          <Route path={CustomRoutes.ABOUT_US} element={<AboutUs />} />
          <Route path={`${CustomRoutes.JOB}/:jobOfferId`} element={<Job />} />
          <Route path={`${CustomRoutes.POST_A_JOB}`} element={<Post />} />
          <Route
            path={`${CustomRoutes.COMPANIES}`}
            element={<SubscribedCompany />}
          />
          <Route
            path={`${CustomRoutes.COMPANY}/:companyId`}
            element={<ViewCompany />}
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </ContentContainer>
    </Container>
  );
};

const Container = styled.div`
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
`;

export default Router;
