import { Controller } from "react-hook-form";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import { ErrorMessage } from "../../../../Styles/CommonComponents";
import CustomTextArea from "../CustomTextArea";

interface Props {
  label: string;
  registerProps: any;
  control: any;
  errorMessage?: string;
  placeholder?: string;
}

const Writer = ({ errorMessage, control, registerProps, ...props }: Props) => {
  const { ref, ...otherRegisterProps } = registerProps;

  return (
    <InputContainer>
      <Controller
        control={control}
        {...otherRegisterProps}
        render={({ field }) => (
          <>
            <CustomTextArea {...props} registerProps={field} />
            {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <Preview isVisible={!!field.value}>
              {!!field.value ? (
                <ReactMarkdown>{field.value}</ReactMarkdown>
              ) : (
                "Bye"
              )}
            </Preview>
          </>
        )}
      />
    </InputContainer>
  );
};

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Preview = styled.div<{ isVisible: boolean }>`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  color: #374151;
  max-width: 65ch;
  font-size: 1rem;
  line-height: 1.75;
  --border-opacity: 1;
  border-color: rgba(229, 231, 235, var(--border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  margin-top: 0.5rem;
  min-width: 100%;
  padding: 1rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);

  animation: ${({ isVisible }) => (isVisible ? "fadeIn" : "fadeOut")} 0.5s
    ease-in;
  animation-fill-mode: forwards;
`;

export default Writer;
