import styled from "styled-components";
import ApplyNowButton from "../../Common/Button/ApplyNowButton";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";

interface Props {
  description: string;
  applyLink?: string;
}

const ExpandedCard = ({ description, applyLink }: Props) => {
  return (
    <Container>
      <ReactMarkdown rehypePlugins={[rehypeRaw]} children={description} />
      {applyLink && (
        <ButtonContainer>
          <ApplyNowButton applyLink={applyLink} />
        </ButtonContainer>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 1.5em;
  padding-top: 1.5em;
  border: 0 solid #d2d6dc;
  --border-opacity: 1;
  border-top-width: 1px;
  color: ${(props) => props.theme.colors.white};
`;

const ButtonContainer = styled.div`
  padding-top: 1em;
  display: flex;
`;

export default ExpandedCard;
