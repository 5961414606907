import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CardContent from "../../Components/JobCard/Card/CardContent";
import ExpandedCard from "../../Components/JobCard/ExpandedCard";
import HomePlaceHolder from "../../Components/PlaceHolder/Home";
import JobOffer from "../../Model/JobOffer";
import JobOfferService from "../../Services/JobOfferService";

const Job = () => {
  const { jobOfferId } = useParams();
  const [jobOffer, setJobOffer] = useState<JobOffer>();

  useEffect(() => {
    JobOfferService.getJobOffer(jobOfferId!).then(setJobOffer);
  }, [jobOfferId]);

  if (!jobOffer) {
    return <HomePlaceHolder />;
  }

  return (
    <div>
      <CardContent jobOffer={jobOffer}>
        <ExpandedCard
          description={jobOffer.description}
          applyLink={jobOffer.applyLink}
        />
      </CardContent>
    </div>
  );
};

export default Job;
