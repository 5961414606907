import { ReactNode } from "react";
import styled from "styled-components";

interface Props {
  children: ReactNode;
}

const Paper = ({ children }: Props) => {
  return <Container>{children}</Container>;
};

const Container = styled.section`
  display: flex;
  background-color: ${({ theme }) => theme.colors.white};
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  --bg-opacity: 1;
  overflow: hidden;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
`;

export default Paper;
