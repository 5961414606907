import styled from "styled-components";
import { device } from "../../../Constants/Devices";
import Dimensions from "../../../Constants/Dimensions";
import CustomRoutes from "../../../Routes/CustomRoutes";
import { CommonLink, ContainedButton } from "../../../Styles/CommonComponents";
import Logo from "../Logo";
import BurgerMenu from "./BurgerMenu";

const Header = () => {
  return (
    <RootContainer>
      <Container>
        <BurgerMenu />
        <LogoContainer>
          <CommonLink to={CustomRoutes.HOME}>
            <Logo />
          </CommonLink>
        </LogoContainer>
        <TitleContainer>
          <CommonLink to={CustomRoutes.ABOUT_US}>
            <Title>About us</Title>
          </CommonLink>
          <CommonLink to={CustomRoutes.COMPANIES}>
            <Title>Companies</Title>
          </CommonLink>
        </TitleContainer>
        <CommonLink to={CustomRoutes.POST_A_JOB}>
          <ButtonContainer>
            <ContainedButton>Post a Job</ContainedButton>
          </ButtonContainer>
        </CommonLink>
      </Container>
    </RootContainer>
  );
};

const RootContainer = styled.section`
  display: flex;
  justify-content: center;
  height: 7vh;
  background-color: ${(props) => props.theme.colors.foreground};
  box-shadow: ${(props) => props.theme.boxShadow.unit};
  padding: 1em 0;
`;

const Container = styled.div`
  width: ${Dimensions.container.mobile.width};
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;

  @media screen and ${device.laptop} {
    width: ${Dimensions.container.laptop.width};
  }
`;

const LogoContainer = styled.div`
  cursor: pointer;
`;

const TitleContainer = styled.nav`
  flex-grow: 0.1;
  display: none;
  flex-direction: row;
  justify-content: space-between;

  @media screen and ${device.tablet} {
    display: flex;
  }
`;

const ButtonContainer = styled.nav`
  display: none;
  flex-direction: row;
  justify-content: flex-end;

  @media screen and ${device.tablet} {
    display: flex;
  }

  @media screen and ${device.desktop} {
    font-size: 1.25em;
  }
`;

const Title = styled.p<{ disabled?: boolean }>`
  color: ${(props) => props.theme.colors.white};
  font-size: 1.5em;

  &:hover {
    cursor: pointer;
    opacity: 0.5;
  }

  ${(props) =>
    props.disabled &&
    `
      opacity: 0.5;

      &:hover {
        cursor: default;
      }
  `}
`;

export default Header;
