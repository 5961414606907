const Dimensions = {
    container: {
        mobile: {
            width: '90%',
        },
        laptop: {
            width: '80%',
        },
    },
    header: {
        height: 90,
    },
}

export default Dimensions;