import styled from "styled-components";
import { device } from "../../../Constants/Devices";
import Dimensions from "../../../Constants/Dimensions";

interface Props {
  children: React.ReactNode;
}

const ContentContainer = (props: Props) => {
  return (
    <Container>
      <Content>{props.children}</Content>
    </Container>
  );
};

const Container = styled.section`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  background: ${(props) => props.theme.colors.background};
  min-height: calc(
    100vh - ${Dimensions.header.height}px
  ); /* 100vh - header height - footer*/
  width: 100%;
`;

const Content = styled.div`
  width: ${Dimensions.container.mobile.width};
  height: 100%;
  padding-top: 4em;

  @media screen and ${device.laptop} {
    width: ${Dimensions.container.laptop.width};
  }
`;

export default ContentContainer;
