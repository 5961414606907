import styled from "styled-components";
import CustomRoutes from "../../../Routes/CustomRoutes";
import { CommonLink } from "../../../Styles/CommonComponents";

interface Props {
  open: boolean;
  close: () => void;
}

const Menu = ({ open, close }: Props) => {
  return (
    <StyledMenu open={open}>
      <CommonLink to={CustomRoutes.ABOUT_US} onClick={close}>
        <span role="img" aria-label="about us">
          💁🏻‍♂️
        </span>
        About us
      </CommonLink>
      <CommonLink to={CustomRoutes.HOME} onClick={close}>
        <span role="img" aria-label="home">
          🏠
        </span>
        Home
      </CommonLink>
      <CommonLink to={CustomRoutes.COMPANIES} onClick={close}>
        <span role="img" aria-label="companies">
          🏢
        </span>
        Companies
      </CommonLink>
      <CommonLink to={CustomRoutes.POST_A_JOB} onClick={close}>
        <span role="img" aria-label="post a job">
          💼
        </span>
        Post a Job
      </CommonLink>
    </StyledMenu>
  );
};

const StyledMenu = styled.nav<{ open: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: ${(props) => props.theme.colors.foreground};
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;

  @media (max-width: 576px) {
    width: 100%;
  }

  a {
    font-size: 2rem;
    text-transform: uppercase;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.5rem;
    color: ${(props) => props.theme.colors.white};
    text-decoration: none;
    transition: color 0.3s linear;

    @media (max-width: 576px) {
      font-size: 1.5rem;
      text-align: center;
    }

    &:hover {
      color: ${(props) => props.theme.colors.to};
    }
  }

  z-index: 1;
`;

export default Menu;
