import useCountries from "../../../../Hooks/useCountries";
import CustomAutocomplete from "../CustomAutocomplete";

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  label: string;
  registerProps: any;
  control: any;
  errorMessage?: string;
}

const CountryInput = (props: Props) => {
  const { countries } = useCountries();

  return <CustomAutocomplete {...props} options={countries} />;
};

export default CountryInput;
