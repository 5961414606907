import { ContainedButton } from "../../../Styles/CommonComponents";

interface Props {
  applyLink: string;
  text?: string;
}

const ApplyNowButton = ({ applyLink, text }: Props) => {
  const handleClick = () => window.open(applyLink);
  return (
    <ContainedButton onClick={handleClick}>
      {text ? text : "Apply Now"}
    </ContainedButton>
  );
};

export default ApplyNowButton;
