import { useEffect, useState } from "react";
import JobCardList from "../../Components/JobCard/JobCardList";
import CompanyCardList from "../../Components/CompanyCard/CompanyCardList";
import HomePlaceHolder from "../../Components/PlaceHolder/Home";
import JobOffer from "../../Model/JobOffer";
import Company from "../../Model/Company";
import JobOfferService from "../../Services/JobOfferService";
import CompanyService from "../../Services/CompanyService";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { DocumentData, QueryDocumentSnapshot } from "firebase/firestore/lite";

const Home = () => {
  const { search } = useLocation();

  const [jobOfferCursor, setJobOfferCursor] =
    useState<QueryDocumentSnapshot<DocumentData> | null>(null);
  const [isLastJobOffer, setIsLastJobOffer] = useState<boolean>(false);
  const [currentCountry, setCurrentCountry] = useState<string | undefined>(
    undefined
  );
  const [currentJobOffers, setCurrentJobOffers] = useState<JobOffer[]>([]);
  const [currentCompanies, setCurrentCompanies] = useState<Company[]>([]);

  const fetchMoreJobOffers = async () => {
    const { jobOffers, newCursor } = await JobOfferService.getJobOffers(
      currentCountry,
      jobOfferCursor
    );

    const allJobOffers = [...currentJobOffers, ...jobOffers];

    if (allJobOffers.length === currentJobOffers.length) {
      setIsLastJobOffer(true);
    }

    setCurrentJobOffers(allJobOffers);
    setJobOfferCursor(newCursor);
  };

  const filterByCountry = async (country: string) => {
    const { jobOffers, newCursor } = await JobOfferService.getJobOffers(
      country
    );

    setCurrentCountry(country);
    setCurrentJobOffers(jobOffers);
    setJobOfferCursor(newCursor);
    setIsLastJobOffer(false);
  };

  useEffect(() => {
    if (search.includes("success")) {
      toast.success("Job offer created successfully!", {
        toastId: "jobOfferCreated",
      });
    }

    if (search.includes("cancel")) {
      toast.error("Job offer creation failed. No charge was made.", {
        toastId: "jobOfferCancelled",
      });
    }

    JobOfferService.getJobOffers().then(({ jobOffers, newCursor }) => {
      setCurrentJobOffers(jobOffers);
      setJobOfferCursor(newCursor);
    });

    CompanyService.getCompanies().then(({ companies }) => {
      setCurrentCompanies(companies);
    });
  }, []);

  if (!currentJobOffers.length && !currentCompanies.length) {
    return <HomePlaceHolder />;
  }

  return (
    <>
      <CompanyCardList companies={currentCompanies} />
      <JobCardList
        jobOffers={currentJobOffers}
        fetchMore={fetchMoreJobOffers}
        isLast={isLastJobOffer}
        filterByCountry={filterByCountry}
      />
    </>
  );
};

export default Home;
