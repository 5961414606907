import { DocumentData, QuerySnapshot } from "firebase/firestore/lite";

class CollectionUtils {
  public parse = (snapshot: QuerySnapshot<DocumentData>) => {
    return snapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });
  };
}

export default new CollectionUtils();
