import { useRef } from "react";
import { Controller } from "react-hook-form";
import styled from "styled-components";
import { ErrorMessage, Placeholder } from "../../../../Styles/CommonComponents";
import company_placeholder from "../../../../assets/placeholder/company.png";
import InputLabel from "../InputLabel";
import { device } from "../../../../Constants/Devices";

interface Props {
  registerProps: any;
  control: any;
  errorMessage?: string;
  label?: string;
  isOptional?: boolean;
}

const ImagePicker = ({
  registerProps,
  control,
  errorMessage,
  ...inputLabelProps
}: Props) => {
  const { ref, ...otherRegisterProps } = registerProps;
  const fileInput = useRef<HTMLInputElement>(null);

  const handleChangeImage = async (
    e: React.ChangeEvent<HTMLInputElement>,
    onChange: (e: any) => void
  ) => {
    const image = e.target.files?.[0];
    if (!image) {
      return;
    }

    const imageUrl = URL.createObjectURL(image);
    onChange(imageUrl);
  };

  return (
    <InputContainer>
      <InputLabel {...inputLabelProps} />
      <Controller
        control={control}
        name="photoURL"
        {...otherRegisterProps}
        render={({ field }) => (
          <Container>
            {field.value ? (
              <ImageContainer>
                <Image
                  src={field.value ?? company_placeholder}
                  alt="profilePhoto"
                />
              </ImageContainer>
            ) : (
              <Placeholder />
            )}
            <InputWrapper>
              <Input
                type={"file"}
                ref={fileInput}
                accept="image/*"
                onChange={(e: any) => handleChangeImage(e, field.onChange)}
              />
            </InputWrapper>
          </Container>
        )}
      />
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
};

const ImageContainer = styled.span`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  --bg-opacity: 1;
  background-color: rgba(244, 245, 247, var(--bg-opacity));
  height: 2.5rem;
  overflow: hidden;
  width: 2.5rem;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const InputWrapper = styled.span`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  --border-opacity: 1;
  border-color: rgba(210, 214, 220, var(--border-opacity));
  border-radius: 0.375rem;
  border-width: 1px;
  display: block;
  font-weight: 500;
  line-height: 1rem;
  padding-bottom: 0.5rem;
  padding-top: 0.5rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  --text-opacity: 1;
  color: rgba(55, 65, 81, var(--text-opacity));
  width: 100%;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  margin-top: 0;
  margin-left: 0;

  @media screen and ${device.tablet} {
    margin-left: 1.25rem;
  }
`;

const Input = styled.input`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  --border-opacity: 1;
  --text-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  overflow: visible;
  color: inherit;
  line-height: inherit;
  padding: 0;
`;

const Container = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: Graphik, sans-serif;
  tab-size: 4;
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
  line-height: inherit;
  text-align: left;
  --tw-bg-opacity: 1;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  margin-top: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  overflow: hidden;

  @media screen and ${device.tablet} {
    flex-direction: row;
    align-items: center;
  }
`;

const Image = styled.img`
  -webkit-text-size-adjust: 100%;
  font-family: Graphik, sans-serif;
  tab-size: 4;
  --PhoneInput-color--focus: #03b2cb;
  --PhoneInputInternationalIconPhone-opacity: 0.8;
  --PhoneInputInternationalIconGlobe-opacity: 0.65;
  --PhoneInputCountrySelect-marginRight: 0.35em;
  --PhoneInputCountrySelectArrow-width: 0.3em;
  --PhoneInputCountrySelectArrow-marginLeft: var(
    --PhoneInputCountrySelect-marginRight
  );
  --PhoneInputCountrySelectArrow-borderWidth: 1px;
  --PhoneInputCountrySelectArrow-opacity: 0.45;
  --PhoneInputCountrySelectArrow-color: inherit;
  --PhoneInputCountrySelectArrow-color--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountrySelectArrow-transform: rotate(45deg);
  --PhoneInputCountryFlag-aspectRatio: 1.5;
  --PhoneInputCountryFlag-height: 1em;
  --PhoneInputCountryFlag-borderWidth: 1px;
  --PhoneInputCountryFlag-borderColor: rgba(0, 0, 0, 0.5);
  --PhoneInputCountryFlag-borderColor--focus: var(--PhoneInput-color--focus);
  --PhoneInputCountryFlag-backgroundColor--loading: rgba(0, 0, 0, 0.1);
  line-height: inherit;
  text-align: left;
  --tw-bg-opacity: 1;
  border: 0 solid #e5e7eb;
  box-sizing: border-box;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  display: block;
  vertical-align: middle;
  max-width: 100%;
  height: 2rem;
  width: 2rem;
`;

export default ImagePicker;
