import styled from "styled-components";
import Company from "../../../Model/Company";
import { GradientTitle } from "../../../Styles/CommonComponents";
import CardContent from "../Card/CardContent";

interface Props {
  companies: Company[];
}

const CompanyCardList = ({ companies }: Props) => {
  if (!companies.length) {
    return null;
  }

  return (
    <>
      <GradientTitle>Companies</GradientTitle>
      <CardList>
        {companies.map((company, key) => (
          <CardContent key={key} company={company} />
        ))}
      </CardList>
    </>
  );
};

const CardList = styled.ul`
  padding: 0 0 1em 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.4em;
`;

export default CompanyCardList;
