import { Money } from "../types/Money";

class StringUtils {
  public parseToMarkDown = (str: string): string => {
    return str.replace(/\\n/g, "\n");
  };

  public parseMoney = (money: string, currencyCode: Money) => {
    const parsedMoney = parseInt(money);

    let formattedMoney = "";
    if (parsedMoney >= 1000) {
      formattedMoney = `${(parsedMoney / 1000).toFixed(0)}k`;
    } else {
      formattedMoney = parsedMoney.toFixed(0);
    }

    return `${currencyCode}${formattedMoney}`;
  };
}

export default new StringUtils();
