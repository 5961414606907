import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import CardContent from "../../Components/CompanyCard/Card/CardContent";
import ExpandedCard from "../../Components/JobCard/ExpandedCard";
import HomePlaceHolder from "../../Components/PlaceHolder/Home";
import Company from "../../Model/Company";
import CompanyService from "../../Services/CompanyService";

const ViewCompany = () => {
  const { companyId } = useParams();
  const [company, setCompany] = useState<Company>();

  useEffect(() => {
    CompanyService.getCompany(companyId!).then(setCompany);
  }, [companyId]);

  if (!company) {
    return <HomePlaceHolder />;
  }

  return (
    <CardContent company={company}>
      <ExpandedCard description={company.description} />
    </CardContent>
  );
};

export default ViewCompany;
