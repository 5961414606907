import { Controller } from "react-hook-form";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import styled from "styled-components";
import { ErrorMessage } from "../../../../Styles/CommonComponents";

type Option = {
  id: string;
  name: string;
};

interface Props extends React.InputHTMLAttributes<HTMLSelectElement> {
  label: string;
  registerProps: any;
  options: Option[];
  control: any;
  errorMessage?: string;
}

const CustomAutocomplete = ({
  errorMessage,
  registerProps,
  options,
  control,
  ...props
}: Props) => {
  const { ref, ...otherRegisterProps } = registerProps;

  const formatResult = (item: any) => {
    return <DisplayedOption>{item.name}</DisplayedOption>;
  };

  return (
    <InputContainer>
      <Label>{props.label}</Label>
      <Controller
        control={control}
        {...otherRegisterProps}
        render={({ field }) => (
          <ReactSearchAutocomplete
            styling={autocompleteStyles}
            items={options}
            formatResult={formatResult}
            onSelect={(result) => field.onChange(result.name)}
            showIcon={false}
            maxResults={3}
          />
        )}
      />

      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
};

const autocompleteStyles = {
  WebkitTextSizeAdjust: "100%",
  WebkitFontSmoothing: "antialiased",
  "--bg-opacity": 1,
  fontFamily: "inherit",
  margin: 0,
  textTransform: "none",
  color: "inherit",
  WebkitPrintColorAdjust: "exact",
  appearance: "none",
  backgroundColor: "#fff",
  backgroundImage:
    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%239fa6b2' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m7 7 3-3 3 3m0 6-3 3-3-3'/%3E%3C/svg%3E\")",
  backgroundPosition: "right 0.5rem center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "1.5em 1.5em",
  borderRadius: "0.375rem",
  borderWidth: "1px",
  "--border-opacity": 1,
  borderColor: "rgba(210, 214, 220, var(--border-opacity))",
  display: "block",
  width: "100%",
  lineHeight: "1.25rem",
  fontSize: "16px",
  zIndex: 1,
};

const DisplayedOption = styled.span`
  display: block;
  text-align: left;
  padding: 0.5em;
`;

const Label = styled.label`
  padding-right: 0.5em;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5em;
`;

export default CustomAutocomplete;
