import { ReactNode, useMemo } from "react";
import styled from "styled-components";
import { device } from "../../../Constants/Devices";
import Company from "../../../Model/Company";
import CustomRoutes from "../../../Routes/CustomRoutes";
import { Title, CommonLink } from "../../../Styles/CommonComponents";
import Tag, { TagType } from "../../Common/Tag/Tag";
import CompanyInformation from "./CompanyInformation";

interface Props {
  company: Company;
  children?: ReactNode;
}

const CardContent = ({ company, children }: Props) => {
  const routeToNavigate = useMemo(
    () => `${CustomRoutes.COMPANY}/${company.id}`,
    [company]
  );

  return (
    <Container featuredCompany={company.isFeatured}>
      <Content>
        <TagContainer>
          {company.isFeatured && <Tag type={TagType.FEATURED_COMPANY} />}
          {company.isCommunitySponsor && (
            <Tag type={TagType.COMMUNITY_SPONSOR} />
          )}
        </TagContainer>
        <RowContainer>
          <CommonLink to={routeToNavigate}>
            <Logo src={company.photoURL} />
            <Title>{company.name}</Title>
          </CommonLink>
        </RowContainer>
        <TimeZoneContainer>
          <ColumnContainer>
            <CompanyInformation {...company} />
          </ColumnContainer>
        </TimeZoneContainer>
        {children}
      </Content>
    </Container>
  );
};

const Logo = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

const Container = styled.div<{ featuredCompany?: boolean }>`\
  width: 100%;
  height: 100%;

    background-color: ${(props) => props.theme.colors.cardBackground};
    border-radius: ${(props) => props.theme.borderRadius.unit};

    ${(props) =>
      props.featuredCompany &&
      `
      --gradient-to-color: #05505c;
      --gradient-from-color: #161e2e;
      --gradient-color-stops: var(--gradient-from-color),var(--gradient-to-color,rgba(22,30,46,0));
      background-image: linear-gradient(to bottom right,var(--gradient-color-stops));
      `}
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1em;
  gap: 0.2em;
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  padding-bottom: 0.5em;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
  overflow-x: auto;
  justify-content: space-between;

  @media screen and ${device.tablet} {
    flex-direction: row;
    align-items: center;
  }

  gap: 1em;
`;

const TimeZoneContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow-x: auto;
  justify-content: space-between;

  @media screen and ${device.tablet} {
    flex-direction: row;
    align-items: center;
  }

  gap: 1em;
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonContainer = styled.div`
  display: flex;
`;

export default CardContent;
