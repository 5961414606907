import { ReactSearchAutocomplete } from "react-search-autocomplete";
import styled from "styled-components";
import useCountries from "../../../Hooks/useCountries";

interface Props {
  onSelect: (country: string) => void;
  onClear?: () => void;
}

const CountryFilter = ({ onSelect, onClear }: Props) => {
  const { countries } = useCountries();

  const formatResult = (item: any) => {
    return <DisplayedOption>{item.name}</DisplayedOption>;
  };

  return (
    <Container>
      <ReactSearchAutocomplete
        items={countries}
        onSelect={(result) => onSelect(result.name)}
        styling={autocompleteStyles}
        formatResult={formatResult}
        maxResults={3}
        placeholder="Filter by country"
        onClear={onClear}
      />
    </Container>
  );
};

// const Container = styled.div`
//   display: flex;
//   width: 100%;
// `;

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5em;
`;

const autocompleteStyles = {
  WebkitTextSizeAdjust: "100%",
  WebkitFontSmoothing: "antialiased",
  "--bg-opacity": 1,
  fontFamily: "inherit",
  margin: 0,
  textTransform: "none",
  color: "inherit",
  WebkitPrintColorAdjust: "exact",
  appearance: "none",
  backgroundColor: "#fff",
  backgroundImage:
    "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%239fa6b2' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m7 7 3-3 3 3m0 6-3 3-3-3'/%3E%3C/svg%3E\")",
  backgroundPosition: "right 0.5rem center",
  backgroundRepeat: "no-repeat",
  backgroundSize: "1.5em 1.5em",
  borderRadius: "0.375rem",
  borderWidth: "1px",
  "--border-opacity": 1,
  borderColor: "rgba(210, 214, 220, var(--border-opacity))",
  display: "block",
  width: "100%",
  lineHeight: "1.25rem",
  fontSize: "16px",
  zIndex: 1,
};

const DisplayedOption = styled.span`
  display: block;
  text-align: left;
  padding: 0.5em;
`;

export default CountryFilter;
