import styled from "styled-components";
import { device } from "../../../Constants/Devices";

interface Props {
  timezones: string[];
  size: number;
}

const renderTimezone = (timezone: string, key: number) => {
  if (key === 0) {
    return `${timezone}`;
  }

  return <DividerContainer key={key}>{timezone}</DividerContainer>;
};

const CompanyInformation = (props: Props) => {
  return (
    <RootContainer>
      <TimezonesContainer>
        <label>Hiring in: </label>
        {props.timezones.map(renderTimezone)}
      </TimezonesContainer>
      {`Company size: ${props.size}+ Developers`}
    </RootContainer>
  );
};

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: ${(props) => props.theme.colors.white};
  gap: 0.1em;
`;

const TimezonesContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and ${device.mobileL} {
    flex-direction: row;
    align-items: center;
    gap: 1em;
  }
`;

const DividerContainer = styled.div`
  display: flex;
  flex-drection: row;
  padding-bottom: 0.2em;

  @media screen and ${device.mobileL} {
    border: 0 solid #d2d6dc;
    border-left-width: 1px;
    padding-left: 1em;
  }
`;

export default CompanyInformation;
