import { TagType } from "../Components/Common/Tag/Tag";
import JobOffer from "../Model/JobOffer";
import StringUtils from "./StringUtils";

class JobOfferUtils {
  public parse = (rawJobOffer: any): JobOffer => {
    return {
      ...rawJobOffer,
      description: StringUtils.parseToMarkDown(rawJobOffer.description),
      publishedDate: new Date(rawJobOffer.publishedDate),
    } as JobOffer;
  };

  public sortByDate = (jobOffers: JobOffer[]): JobOffer[] => {
    return jobOffers.sort((a, b) => {
      return b.publishedDate.getTime() - a.publishedDate.getTime();
    });
  };

  public parseCity = (city: string | undefined): string => {
    if (
      !city ||
      city.toLocaleLowerCase().includes(TagType.REMOTE.toLocaleLowerCase())
    ) {
      return TagType.REMOTE;
    }

    const cityArray = city.split(",");
    if (cityArray.length > 1) {
      return cityArray[0];
    }

    return city;
  };
}

export default new JobOfferUtils();
