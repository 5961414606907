import { Elements } from "@stripe/react-stripe-js";
import { loadStripe, StripeElementsOptions } from "@stripe/stripe-js";
import { useState, useEffect } from "react";
import HomePlaceHolder from "../../Components/PlaceHolder/Home";
import PostForm from "../../Components/PostForm";
import CONFIG from "../../config/config";
import FirebaseService from "../../Services/FirebaseService";
import { GradientTitle } from "../../Styles/CommonComponents";

const stripePromise = loadStripe(CONFIG.STRIPE.KEY as string);
const mode = CONFIG.ENV;

const params = {
  success_url: CONFIG.STRIPE.SUCCESS_URL,
  cancel_url: CONFIG.STRIPE.CANCEL_URL,
  mode,
};

const Post = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<StripeElementsOptions>();

  useEffect(() => {
    setIsLoading(true);
    FirebaseService.getStripeClientSecret(params)
      .then(({ data }) => {
        setOptions({
          clientSecret: data.client_secret,
        });
      })
      .finally(() => setIsLoading(false));
  }, []);

  if (isLoading) return <HomePlaceHolder />;
  if (!options) return null;

  return (
    <Elements stripe={stripePromise} options={options}>
      <GradientTitle>Create a new opening</GradientTitle>
      <PostForm />
    </Elements>
  );
};

export default Post;
