import {
  collection,
  getDocs,
  where,
  query,
  addDoc,
  QueryDocumentSnapshot,
  DocumentData,
  limit,
  startAfter,
} from "firebase/firestore/lite";
import Company from "../Model/Company";
import CompanyUtils from "../Utils/CompanyUtils";
import FirebaseService, { db } from "./FirebaseService";
import StorageService from "./StorageService";
import { v4 as uuidv4 } from "uuid";
import CONFIG from "../config/config";
import CollectionUtils from "../Utils/CollectionUtils";

class CompanyService {
  public async getFeaturedCompanies(): Promise<Company[]> {
    const q = query(
      collection(db, CONFIG.FIRESTORE.COLLECTIONS.COMPANIES),
      where("isFeatured", "==", true)
    );
    const querySnapshot = await getDocs(q);
    const companies = querySnapshot.docs.map((doc) => {
      return {
        ...doc.data(),
        id: doc.id,
      };
    });

    return companies.map(CompanyUtils.parse);
  }

  public getCompany = async (companyId: string): Promise<Company> => {
    const rawJobOffer = await FirebaseService.getFirestoreDocumentById(
      CONFIG.FIRESTORE.COLLECTIONS.COMPANIES,
      companyId
    );
    return CompanyUtils.parse(rawJobOffer);
  };

  public getCompanies = async (
    cursor: QueryDocumentSnapshot<DocumentData> | null = null,
    currentLimit: number = 10
  ) => {
    const constraints = [where("description", "!=", null), limit(currentLimit)];

    if (cursor) {
      constraints.push(startAfter(cursor));
    }

    const q = query(
      collection(db, CONFIG.FIRESTORE.COLLECTIONS.COMPANIES),
      ...constraints
    );

    const snapshot = await getDocs(q);
    const newCursor = snapshot.docs[snapshot.docs.length - 1];

    const rawCompanies = CollectionUtils.parse(snapshot);
    const companies = rawCompanies.map(CompanyUtils.parse);

    return {
      companies,
      newCursor,
    };
  };

  public createCompany = async (company: Company): Promise<Company> => {
    let photoURL = "";
    const uuid = uuidv4();

    if (company.photoURL) {
      photoURL = await StorageService.uploadFile(
        company.photoURL,
        `companies/${uuid}/photo`
      );
    }

    const updatedCompany = {
      ...company,
      photoURL,
    };

    const companyCollection = collection(
      db,
      CONFIG.FIRESTORE.COLLECTIONS.COMPANIES
    );

    const companyDocRef = await addDoc(companyCollection, updatedCompany);

    return {
      ...updatedCompany,
      id: companyDocRef.id,
    };
  };
}

export default new CompanyService();
