import { useRef, useState } from "react";
import styled from "styled-components";
import { device } from "../../../Constants/Devices";
import Burger from "./Burger";
import Menu from "./Menu";

const BurgerMenu = () => {
  const [open, setOpen] = useState(false);
  const node = useRef() as React.MutableRefObject<HTMLInputElement>;

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false);
    }, 500);
  };

  return (
    <MobileContainer ref={node}>
      <Burger open={open} onClick={() => setOpen(!open)} />
      <Menu open={open} close={handleClose} />
    </MobileContainer>
  );
};

const MobileContainer = styled.div`
    display: block;
    z-index: 2;

    @media screen and ${device.tablet} {
        display: none;
    }
}`;

export default BurgerMenu;
