import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { storage } from "./FirebaseService";

const TAG = "STORAGE_SERVICE";

class StorageService {
  private async getBlob(fileUrl: string): Promise<Blob> {
    const res = await fetch(fileUrl);

    if (fileUrl.includes("local")) {
      URL.revokeObjectURL(fileUrl);
    }

    return res.blob();
  }

  public async uploadFile(fileUrl: string, path: string): Promise<string | ""> {
    const file = await this.getBlob(fileUrl);

    const storageRef = ref(storage, path);
    try {
      const result = await uploadBytes(storageRef, file);
      return await getDownloadURL(result.ref);
    } catch (e) {
      console.log(TAG, "There was an issue", e);
      return "";
    }
  }

  public async getFileDownloadURL(
    userUid: string,
    path: string
  ): Promise<string | ""> {
    const userPath = `users/${userUid}/${path}`;
    const storageRef = ref(storage, userPath);
    try {
      return await getDownloadURL(storageRef);
    } catch (e) {
      console.log(TAG, "There was an issue", e);
      return "";
    }
  }
}

export default new StorageService();
