import styled from "styled-components";
import { ErrorMessage } from "../../../../Styles/CommonComponents";
import InputLabel from "../InputLabel";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  registerProps: any;
  errorMessage?: string;
  isOptional?: boolean;
  isVisible?: boolean;
}

const CustomInput = ({
  errorMessage,
  registerProps,
  label,
  isOptional,
  isVisible = true,
  ...props
}: Props) => {
  return (
    <InputContainer isVisible={isVisible}>
      <InputLabel label={label} isOptional={isOptional} />
      <Input {...props} {...registerProps} autoComplete={isVisible} />
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
};

const InputContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5em;

  animation: ${({ isVisible }) => (isVisible ? "fadeIn" : "fadeOut")} 0.5s
    ease-in;
  cursor: ${({ isVisible }) => (isVisible ? "auto" : "none")};
  animation-fill-mode: forwards;
`;

const Input = styled.input`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  color: inherit;
  appearance: none;
  background-color: #fff;
  border-color: #d2d6dc;
  border-width: 1px;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  display: block;
  flex: 1 1 0%;
  min-width: 0;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  line-height: 1.25rem;

  &:focus {
    border-color: #a4cafe;
    box-shadow: 0 0 0 3px rgb(164 202 254 / 45%);
    outline: none;
  }
`;

export default CustomInput;
