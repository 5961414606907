import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  getDoc,
  doc,
} from "firebase/firestore/lite";
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";
import {
  RequestStripeClient,
  RequestStripeSession,
  ResponseStripeClient,
  ResponseStripeSession,
} from "../types/FunctionsTypes/Stripe";

const firebaseConfig = {
  apiKey: "AIzaSyBPGF998b87_0WkLxSGfDg5v-ByJRinjnQ",
  authDomain: "jobs-5d7ae.firebaseapp.com",
  projectId: "jobs-5d7ae",
  storageBucket: "jobs-5d7ae.appspot.com",
  messagingSenderId: "462826202462",
  appId: "1:462826202462:web:ec7239452dc2f53f035bcf",
  measurementId: "G-ZT5GKM2P87",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);

const getFirestoreDocumentById = async (
  collectionName: string,
  collectionId: string
) => {
  const docRef = doc(db, collectionName, collectionId);
  const docSnapshot = await getDoc(docRef);

  return {
    ...docSnapshot.data(),
    id: docSnapshot.id,
  };
};

const getStripeClientSecret = httpsCallable<
  RequestStripeClient,
  ResponseStripeClient
>(functions, "getStripeClientSecret");

const createStripeCheckoutSession = httpsCallable<
  RequestStripeSession,
  ResponseStripeSession
>(functions, "createStripeCheckoutSession");

const FirebaseService = {
  getFirestoreDocumentById,
  createStripeCheckoutSession,
  getStripeClientSecret,
};

export default FirebaseService;
