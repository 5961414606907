import { useMemo } from "react";
import * as countryList from "country-list";
import getUnicodeFlagIcon from "country-flag-icons/unicode";

const useCountries = (countryName?: string) => {
  const countries = useMemo(
    () =>
      countryList.getData().map(({ code, name }) => ({
        id: code,
        name,
      })),
    []
  );

  const flag = useMemo(() => {
    if (!countryName) {
      return;
    }

    const countryCode = countryList.getCode(countryName);

    if (!countryCode) {
      return;
    }

    return getUnicodeFlagIcon(countryCode);
  }, [countryName]);

  return { countries, flag };
};

export default useCountries;
