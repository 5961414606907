import styled from "styled-components";

const Logo = () => {
  return <Text>JavaJobs</Text>;
};

const Text = styled.span`
  transition: 0.5s;
  background-size: 200% auto;
  border-radius: 10px;
  cursor: pointer;
  color: transparent;
  font-size: 2em;
  font-weight: 700;
  -webkit-background-clip: text;

  background-image: linear-gradient(
    to right,
    ${(props) => props.theme.colors.from} 0%,
    ${(props) => props.theme.colors.to} 51%,
    ${(props) => props.theme.colors.from} 100%
  );

  &:hover {
    background-position: right center;
    font-weight: 800;
  }
`;

export default Logo;
