import styled from "styled-components";
import ICONS from "../../../assets";
import DateUtils from "../../../Utils/DateUtils";


interface Props {
  date: Date;
}

const PublishedDate = ({ date }: Props) => {
    const timeAgo = DateUtils.getTimeAgo(date);

    return (
        <Container>
            <ICONS.Calendar width="1.2em" height="1.2em"/>
            {timeAgo}
        </Container>
    )
};

const Container = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 0.5em;
    color: ${(props) => props.theme.colors.white};
    opacity: 0.8;
`

export default PublishedDate;
