import {
  LabelContainer,
  Label,
  OptionalLabel,
} from "../../../../Styles/CommonComponents";

interface Props {
  label?: string;
  isOptional?: boolean;
}

const InputLabel = ({ label, isOptional }: Props) => {
  return (
    <LabelContainer>
      <Label>{label}</Label>
      {isOptional && <OptionalLabel>Optional</OptionalLabel>}
    </LabelContainer>
  );
};

export default InputLabel;
