export enum Environment {
  PROD = "prod",
  DEV = "dev",
}

const APP_NAME = "java";
const ENV = process.env.REACT_APP_ENV as Environment;

const PRICE = (
  ENV === Environment.PROD
    ? process.env.REACT_APP_STRIPE_PRODUCT_PRICE_PROD
    : process.env.REACT_APP_STRIPE_PRODUCT_PRICE_DEV
) as string;

const KEY = (
  ENV === Environment.PROD
    ? process.env.REACT_APP_STRIPE_KEY_PROD
    : process.env.REACT_APP_STRIPE_KEY_DEV
) as string;

const CONFIG = {
  ENV,
  STRIPE: {
    KEY,
    PRICE,
    SUCCESS_URL: `${window.location.origin}?success`,
    CANCEL_URL: `${window.location.origin}?cancel`,
  },
  APP_NAME,
  FIRESTORE: {
    COLLECTIONS: {
      JOB_OFFERS: `${ENV}-${APP_NAME}-jobs`,
      COMPANIES: `${ENV}-${APP_NAME}-companies`,
    },
  },
};

export default CONFIG;
