const gradient = {
    from: "#ff8a4c",
    to: "#e02424",
    textHovered: "#f98080",
    cardBackground: "rgb(22, 30, 46)",
    shimmerFrom: "#f1f2f4",
    shimmerTo: "#bdc3cf",
}

const lightTheme = {
    colors: {
        primary: '#00bcd4',
        secondary: '#ff9800',
        background: '#F4F5F7',
        backgroundOpacity: "#d5d9e0",
        foreground: '#212121',
        text: '#212121',
        white: '#ffffff',
        border: '#bdbdbd',
        error: '#f44336',
        success: '#4caf50',
        warning: '#ffeb3b',
        info: '#2196f3',
        ...gradient,
    },
    font: {
        family: 'Roboto',
        size: '14px',
        weight: '400',
    },
    spacing: {
        unit: '8px',
    },
    borderRadius: {
        unit: '6px',
    },
    boxShadow: {
        unit: '0px 0px 10px 0px rgba(0, 0, 0, 0.75)',
    },
    transition: {
        unit: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    },
};

const darkTheme = {
    colors: {
        primary: '#00bcd4',
        secondary: '#ff9800',
        background: '#212121',
        foreground: '#212121',
        white: '#ffffff',
        text: '#fafafa',
        border: '#bdbdbd',
        error: '#f44336',
        success: '#4caf50',
        warning: '#ffeb3b',
        info: '#2196f3',
        ...gradient,
    },
    font: {
        family: 'Roboto',
        size: '14px',
        weight: '400',
    },
    spacing: {
        unit: '8px',
    },
    borderRadius: {
        unit: '4px',
    },
    boxShadow: {
        unit: '0px 0px 10px 0px rgba(0, 0, 0, 0.75)',
    },
    transition: {
        unit: 'all 0.3s cubic-bezier(.25,.8,.25,1)',
    },
};

export const theme = {
    light: lightTheme,
    dark: darkTheme,
};