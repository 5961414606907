import { Money } from "../types/Money";

export enum JobOfferStatus {
  PUBLISHED = "published",
  UNPUBLISHED = "unpublished",
}

class JobOffer {
  constructor(
    public id: string,
    public publishedDate: Date,
    public title: string,
    public city: string,
    public companyName: string,
    public applyLink: string,
    public description: string,
    public email: string,
    public country: string,
    public currency: Money,
    public remote?: boolean,
    public featuredCompany?: boolean,
    public communitySponsor?: boolean,
    public salaryFrom?: string,
    public salaryTo?: string,
    public companyId?: string,
    public photoURL?: string,
    public status?: JobOfferStatus
  ) {}
}

export default JobOffer;
