import styled from "styled-components";
import JobOffer from "../../../Model/JobOffer";
import CardContent from "./CardContent";

interface Props {
  jobOffer: JobOffer;
}

const Card = ({ jobOffer }: Props) => {
  return (
    <Border featuredCompany={jobOffer.featuredCompany}>
      <CardContent jobOffer={jobOffer} />
    </Border>
  );
};

const Border = styled.li<{ featuredCompany?: boolean }>`
  width: 100%;
  list-style: none;

  ${(props) =>
    props.featuredCompany &&
    `
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background-image: linear-gradient(to bottom right,var(--gradient-color-stops));
    --gradient-from-color: #ff8a4c;
    --gradient-color-stops: var(--gradient-from-color),var(--gradient-to-color,rgba(255,138,76,0));
    --gradient-to-color: #e02424;
    padding: .25rem;
    box-shadow: 0 10px 15px -3px rgba(0,0,0,.1),0 4px 6px -2px rgba(0,0,0,.05);
    border-radius: .375rem;
    `}
`;

export default Card;
