import { useStripe } from "@stripe/react-stripe-js";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import styled from "styled-components";
import CONFIG from "../../config/config";
import Company from "../../Model/Company";
import JobOffer, { JobOfferStatus } from "../../Model/JobOffer";
import CompanyService from "../../Services/CompanyService";
import FirebaseService from "../../Services/FirebaseService";
import JobOfferService from "../../Services/JobOfferService";
import { ContainedButton } from "../../Styles/CommonComponents";
import { Money } from "../../types/Money";
import CountryInput from "../Common/Form/CountryInput";
import CustomInput from "../Common/Form/CustomInput";
import CustomRadioInput from "../Common/Form/CustomRadioInput";
import ImagePicker from "../Common/Form/ImagePicker";
import MoneyInput from "../Common/Form/MoneyInput/Index";
import Writer from "../Common/Form/Writer";
import Paper from "../Common/Paper";

const price = CONFIG.STRIPE.PRICE;
const mode = CONFIG.ENV;

const PostForm = () => {
  const stripe = useStripe();
  const [isLoading, setIsLoading] = useState(false);
  const [currency, setCurrency] = useState(Money.USD);

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: "onChange",
    defaultValues: {
      remote: "true",
      currency: Money.USD,
      title: "",
      description: "",
      companyName: "",
      photoURL: "",
      country: "",
      city: "",
      salary: "",
      email: "",
      phone: "",
      website: "",
      applyLink: "",
      salaryFrom: "",
      salaryTo: "",
      location: "",
    },
  });

  const isRemote = watch("remote", "true") === "true";

  const redirectToCheckout = async (jobOfferId: string) => {
    if (!stripe) {
      return;
    }

    const checkoutSessionParams = {
      price,
      success_url: CONFIG.STRIPE.SUCCESS_URL,
      cancel_url: CONFIG.STRIPE.CANCEL_URL,
      mode,
      jobOfferId,
      collectionName: CONFIG.FIRESTORE.COLLECTIONS.JOB_OFFERS,
    };

    const { data } = await FirebaseService.createStripeCheckoutSession(
      checkoutSessionParams
    );

    stripe.redirectToCheckout({ sessionId: data.session_id });
  };

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    const company = {
      name: data.companyName,
      photoURL: data.photoURL,
    } as Company;

    const { id, photoURL } = await CompanyService.createCompany(company);

    const jobOffer = {
      ...data,
      photoURL,
      companyId: id,
      status: JobOfferStatus.UNPUBLISHED,
      currency: currency,
    } as JobOffer;

    JobOfferService.createJobOffer(jobOffer)
      .then((currentJobOffer) => {
        redirectToCheckout(currentJobOffer.id);
      })
      .catch((err) => {
        console.log(err);
        toast.error("Error creating job offer");
        setIsLoading(false);
      });
  };

  const isDisabled = !isDirty || !isValid;

  return (
    <Paper>
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <CustomInput
          label="Job Title"
          placeholder="Java Developer"
          registerProps={register("title", { required: true, minLength: 3 })}
          errorMessage={
            errors.title &&
            "Title is required and must be at least 3 characters"
          }
        />
        <CustomInput
          label="Company Name"
          placeholder="Acme Inc"
          registerProps={register("companyName", {
            required: true,
            minLength: 3,
          })}
          errorMessage={
            errors.companyName &&
            "Company name is required and must be at least 3 characters"
          }
        />
        <ImagePicker
          registerProps={register("photoURL", { required: false })}
          control={control}
          isOptional
          label="Company Logo"
        />
        <Writer
          label="Job Description"
          registerProps={register("description", { required: true })}
          errorMessage={errors.description && "Description is required"}
          control={control}
          placeholder="Write a few sentences about the job. Markdown is supported."
        />
        <CustomInput
          label="Job Application Link"
          placeholder="https://example.com/job or mailto:you@example.com"
          registerProps={register("applyLink", {
            required: true,
            validate: (value) =>
              value.startsWith("http") || value.startsWith("mailto"),
          })}
          errorMessage={errors.applyLink && "Invalid link"}
        />
        <MoneyInput
          label="From"
          type="number"
          placeholder="100 000"
          registerProps={register("salaryFrom", { required: false })}
          errorMessage={errors.salaryFrom && "An email is required"}
          isOptional
          onChangeCurrency={setCurrency}
          currency={currency}
        />
        <MoneyInput
          label="To"
          placeholder="150 000"
          type="number"
          registerProps={register("salaryTo", { required: false })}
          isOptional
          onChangeCurrency={setCurrency}
          currency={currency}
        />

        <CustomInput
          label="Email address"
          placeholder="you@example.com"
          registerProps={register("email", { required: true })}
          errorMessage={errors.email && "An email is required"}
        />

        <CustomRadioInput
          label="Remote friendly"
          registerProps={register("remote", { required: true })}
          options={[
            { name: "Remote", value: true, default: true },
            // { name: "Partially Remote", value: false },
            { name: "On Site", value: false },
          ]}
        />
        <CountryInput
          label="Country"
          placeholder="Peru"
          control={control}
          registerProps={register("country", { required: !isRemote })}
          errorMessage={errors.country && "A country is required"}
        />
        <CustomInput
          label="City"
          placeholder="City"
          registerProps={register("city", { required: !isRemote })}
          isVisible={!isRemote}
          errorMessage={errors.city && "A city is required"}
        />
        <ButtonContainer type="submit" disabled={isDisabled || isLoading}>
          <ContainedButton isDisabled={isDisabled} isLoading={isLoading}>
            {!isLoading && "Send"}
          </ContainedButton>
        </ButtonContainer>
      </FormContainer>
    </Paper>
  );
};

const ButtonContainer = styled.button`
  padding: 0;
  border: none;
  display: flex;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
`;

const FormContainer = styled.form`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 1em;
  gap: 1.25em;

  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  --bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--bg-opacity));
  overflow: hidden;
`;

export default PostForm;
