import styled from "styled-components";
import { ErrorMessage } from "../../../../Styles/CommonComponents";

interface Props extends React.InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  registerProps: any;
  errorMessage?: string;
}

const CustomTextArea = ({ errorMessage, registerProps, ...props }: Props) => {
  return (
    <InputContainer>
      <Label>{props.label}</Label>
      <TextArea rows={12} {...props} {...registerProps} />
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
};

const Label = styled.label`
  padding-right: 0.5em;
`;

const InputContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5em;
`;

const TextArea = styled.textarea`
  resize: none;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  color: inherit;
  appearance: none;
  background-color: #fff;
  border-color: #d2d6dc;
  border-width: 1px;
  font-size: 1rem;
  padding: 0.5rem 0.75rem;
  border-radius: 0.375rem;
  display: block;
  flex: 1 1 0%;
  min-width: 0;
  width: 100%;
  transition-property: background-color, border-color, color, fill, stroke,
    opacity, box-shadow, transform;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 0.15s;
  line-height: 1.25rem;

  &:focus {
    border-color: #a4cafe;
    box-shadow: 0 0 0 3px rgb(164 202 254 / 45%);
    outline: none;
  }
`;

export default CustomTextArea;
