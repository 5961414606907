import styled from "styled-components";
import { ErrorMessage } from "../../../../Styles/CommonComponents";
import { Money } from "../../../../types/Money";
import InputLabel from "../InputLabel";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  registerProps: any;
  onChangeCurrency: (currency: Money) => void;
  currency: string;
  errorMessage?: string;
  isOptional?: boolean;
  isVisible?: boolean;
}

const MONEY_OPTIONS = [
  { value: Money.USD, label: Money.USD },
  { value: Money.EUR, label: Money.EUR },
  { value: Money.GBP, label: Money.GBP },
  { value: Money.ARS, label: Money.ARS },
];

const MoneyInput = ({
  errorMessage,
  registerProps,
  label,
  isOptional,
  isVisible = true,
  onChangeCurrency,
  currency,
  ...props
}: Props) => {
  return (
    <InputContainer isVisible={isVisible}>
      <InputLabel label={label} isOptional={isOptional} />
      <InputWrapper>
        <MoneyWrapper>
          <MoneySelect
            onChange={(e) => onChangeCurrency(e.target.value as Money)}
            value={currency}
          >
            {MONEY_OPTIONS.map(({ value, label }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </MoneySelect>
        </MoneyWrapper>
        <Input {...props} {...registerProps} min="0" />
      </InputWrapper>
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </InputContainer>
  );
};

const Option = styled.option`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  font-family: inherit;
  text-transform: none;
  -webkit-print-color-adjust: exact;
  line-height: 1.5;
  font-size: 1.125rem;
  --text-opacity: 1;
  color: rgba(107, 114, 128, var(--text-opacity));
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
`;

const MoneySelect = styled.select`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  text-transform: none;
  -webkit-print-color-adjust: exact;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%239fa6b2' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='m7 7 3-3 3 3m0 6-3 3-3-3'/%3E%3C/svg%3E");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  border-radius: 0.375rem;
  border-width: 1px;
  line-height: 1.5;
  background-color: transparent;
  border-color: transparent;
  height: 100%;
  font-size: 1.125rem;
  padding-bottom: 0;
  padding-top: 0;
  padding-left: 0.75rem;
  padding-right: 1rem;
  --text-opacity: 1;
  color: rgba(107, 114, 128, var(--text-opacity));
`;

const MoneyWrapper = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
`;

const InputWrapper = styled.div`
  -webkit-text-size-adjust: 100%;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
    Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji,
    Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  border-radius: 0.375rem;
  margin-top: 0.25rem;
  position: relative;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;

const InputContainer = styled.div<{ isVisible: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  gap: 0.5em;

  animation: ${({ isVisible }) => (isVisible ? "fadeIn" : "fadeOut")} 0.5s
    ease-in;
  animation-fill-mode: forwards;
`;

const Input = styled.input`
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  --bg-opacity: 1;
  border: 0 solid #d2d6dc;
  box-sizing: border-box;
  font-family: inherit;
  margin: 0;
  overflow: visible;
  color: inherit;
  appearance: none;
  background-color: #fff;
  border-color: #d2d6dc;
  border-radius: 0.375rem;
  border-width: 1px;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0.5rem 0.75rem;
  display: block;
  padding-left: 4rem;
  width: 100%;

  &:focus {
    border-color: #a4cafe;
    box-shadow: 0 0 0 3px rgb(164 202 254 / 45%);
    outline: none;
  }
`;

export default MoneyInput;
