import { IContentLoaderProps } from "react-content-loader";
import { useTheme } from "styled-components";
import { List } from "react-content-loader";

const HomePlaceHolder = (props: IContentLoaderProps) => {
  const theme = useTheme() as any;

  return (
      <List
        speed={2}
        backgroundColor={theme.colors.shimmerFrom}
        foregroundColor={theme.colors.shimmerTo}
        style={{ paddingTop: "2em" }}
        {...props}
      />
  );
};

{
  /* <ContentLoader
  speed={2}
  viewBox="0 0 350 600"
  backgroundColor="#dbdbdb"
  foregroundColor="#dbdbdb"
  {...props}
  >
  <rect x="0" y="0" rx="3" ry="3" width="15%" height="10" />
  <rect x="54" y="0" rx="3" ry="3" width="15%" height="10" />
  <rect x="108" y="0" rx="3" ry="3" width="15%" height="10" />
  <rect x="0" y="20" rx="3" ry="3" width="25%" height="11" />
  <rect x="0" y="40" rx="3" ry="3" width="35%" height="11" />
  <rect x="0" y="60" rx="3" ry="3" width="15%" height="10" />
  
  <rect x="0" y="80" rx="3" ry="3" width="15%" height="10" />
  <rect x="54" y="80" rx="3" ry="3" width="15%" height="10" />
  <rect x="108" y="80" rx="3" ry="3" width="15%" height="10" />
  <rect x="0" y="100" rx="3" ry="3" width="25%" height="11" />
  <rect x="0" y="40" rx="3" ry="3" width="35%" height="11" />
  <rect x="0" y="60" rx="3" ry="3" width="15%" height="10" />
</ContentLoader> */
}

export default HomePlaceHolder;
